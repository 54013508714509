.table-container {
  display: flex;
  flex-wrap: wrap; /* Allow tables to wrap onto the next line if they overflow */
  gap: 20px; /* Space between tables */
}

.table-item {
  flex: 1 1 calc(25% - 20px); /* Adjust the width of each table; 25% for 4 tables side by side */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.even-row {
  background-color: #f5f5f5; /* Light gray for even rows */
}

.odd-row {
  background-color: #ffffff; /* White for odd rows */
}

.desktop-trio-table .ant-table-cell {
  font-size: 0.8rem;
}

.mobile-trio-table .ant-table-cell {
  font-size: 0.7rem;
}
