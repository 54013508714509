/* ArticleCard.css */
.mainArticleContainer {
  box-shadow: 0 0 10px rgba(1, 72, 123, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  max-width: 400px; /* Optional: restrict card width */
}

.mainArticleContainer:hover {
  box-shadow: 0 0 10px rgba(0, 44, 94, 0.375);
}
.mainArticleContainer:active {
  box-shadow: 0 0 8px rgb(0, 32, 56);
  transform: scale(0.98); /* Adds a slight shrink effect */
}

.articleInfo {
  flex: 1; /* Make articleInfo take remaining space */
  margin-right: 16px; /* Space between content and profile picture */
}

.shortArticleTitle {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 1.3rem;
  font-weight: 700;
}

.shortArticleParagraph {
  margin: 0;
  padding: 0;
  text-align: left;
  color: #666;
}

.dateTime {
  margin: 0;
  font-size: 10px;
  padding-bottom: 8px;
}
