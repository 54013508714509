.signUpBtn {
  border: 1px solid rgb(152, 152, 152);
  font-weight: 700;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 4px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.loginBtn {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: 700;
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.signUpBtnMobile {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 700;
  font-size: 9px;
  margin-left: 4px;
  margin-bottom: 22px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.loginBtnMobile {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 700;
  font-size: 9px;
  margin-bottom: 22px;
  background-color: #000864;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.ant-dropdown {
  width: auto;
}

.profileMenuIcon {
  margin-right: 15px;
}

.custom-switch.ant-switch {
  color: black;
  border-radius: 4;
  background-color: rgb(
    156,
    60,
    60
  ) !important; /* Set background color to white when unchecked */
}

.custom-switch.ant-switch-checked {
  background-color: #000864 !important; /* Set background color for the checked state (default Ant Design blue) */
}

.custom-submenu-popup {
  left: 100% !important; /* Pushes the submenu to the right side */
  top: 0 !important; /* Align the submenu with the top of the parent */
  right: auto !important; /* Prevent it from moving to the left */
}

.custom-submenu-popup .ant-menu-submenu-arrow {
  right: -10px; /* Adjust arrow position if you want it aligned properly */
}

:where(.css-dev-only-do-not-override-98ntnt).ant-drawer .ant-drawer-body {
  padding: 0 !important;
}

.ant-menu-item-group-title {
  padding: 0;
}
.ant-drawer-title {
  padding-top: 4%;
}

.mainArticleContainer:hover {
  box-shadow: 0 0 8px rgba(68, 66, 66, 0.8);
}
