/* Default styles for larger screens */
.channelInfo {
  display: flex;
  margin-bottom: 5%;
  box-shadow: 0 0 8px rgba(68, 66, 66, 0.4);
  max-width: 60%;
  padding-bottom: 10px;
}

.channelInfoItems {
  margin-top: 2%;
  margin-left: 10px;
}

.upickProfile {
  border-radius: 50%;
  object-fit: cover;
}

.subscribe-button {
  background-color: #ff0000;
  color: white;
  padding: 6px;
  border-radius: 20px; /* This gives the button the round shape */
  border: 2px solid #ff0000; /* Changes the border color to red */
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: lightpink;
  border-color: lightpink; /* Changes the border color to match the hover color */
}

.subscribe-button:active {
  background-color: #ff0000; /* Reverts back to the original color on click */
  border-color: #ff0000; /* Reverts the border color back to red */
}

.pagination-center {
  display: flex;
  justify-content: center;
}

/* Responsive styles for tablets */
@media (max-width: 1024px) {
  .channelInfo {
    max-width: 80%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }

  .channelInfoItems {
    margin-left: 0;
    text-align: center;
  }

  .subscribe-button {
    padding: 10px 15px;
    font-size: 16px;
  }
}

/* Responsive styles for smartphones */
@media (max-width: 768px) {
  .channelInfo {
    max-width: 100%;
    max-height: auto;
    padding-bottom: 15px;
    margin-bottom: 10%;
    display: flex;
    flex-direction: row;
  }
  .channelBanner {
    width: 100%;
    height: 150px;
  }

  .channelInfoItems {
    margin-top: 5%;
    margin-left: 4%;
  }

  .channelName {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
  .channelDetails {
    margin: 0;
    margin-left: 4%;
  }

  .subscribe-button {
    padding: 12px 20px;
    font-size: 18px;
    margin-right: 80%;
  }

  .upickProfile {
    width: 80px;
    height: 80px;
    margin-left: 6%;
  }
  .subscribe-button {
    background-color: black;
    color: white;
    font-size: 12px;
    padding: 6px;
    border-radius: 20px; /* This gives the button the round shape */
    border: 2px solid black; /* Changes the border color to red */
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  .profileDescription {
    text-align: left;
    font-size: smaller;
    padding-right: 4%;
    font-size: 12px;
    color: grey;
    max-width: 100%;
  }
}

/* Responsive styles for large screens */
@media (min-width: 1440px) {
  .channelInfo {
    max-width: 50%;
  }

  .subscribe-button {
    padding: 8px 12px;
    font-size: 14px;
  }
}
