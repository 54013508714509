.registration-container {
  max-width: 80%;
  /* margin: 0 auto;
  margin-top: 5%;
  padding: 40px 20px;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px; */
}

.registration-title {
  text-align: center;
  margin-bottom: 20px;
}

.registration-form {
  width: 100%;
}

.registration-button {
  width: 100%;
}
