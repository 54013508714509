.footer-body {
  padding: 0;
  margin: 0;
  margin-top: 12px;
  width: 40%;
  line-height: 2;
  font-size: 12px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .footer-title {
    font-size: 20px;
  }

  .footer-body {
    width: 60%;
    font-size: 10px;
  }

  .footer-followLinks {
    font-size: 20px;
    opacity: 0;
  }

  .bottomFooter {
    flex-direction: column;
    text-align: center;
  }

  .bottomFooter p {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
  }

  .bottomFooter div {
    margin-top: 10px;
  }
  .additionalNavLinks {
    opacity: 0;
  }
  .developedAt {
    font-size: 10px;
    padding-top: 10px;
    padding-left: 0px;
  }
}
