:root {
  --padding-large: 100px;
  --padding-medium: 20px; /* Adjusted for smaller screens */
  --padding-small: 10px;
  --margin-medium: 20px;
  --color-black: #000000;
  --color-brown: #f1f1f1;
  --outline-color: #000000;
  --temp-color: rgba(190, 220, 235, 0.432);
  --max-height-upcoming-matches: 500px; /* You can adjust this value as needed */
}

/* Main container styling */
.bodyContainer {
  flex-grow: 1;
}

/* Banner area styling */
.bannerArea {
  width: 100%;
  padding-top: 0%;
  margin-top: 0%;
}

/* .bannerImage {
  height: auto;
} */

/* Body area styling */
.bodyArea {
  padding: var(--padding-small);
  background-color: #ffffff;
  margin-top: 2%;
  width: 100%;
  padding: 0px;
  gap: 20px;
  display: flex;
  justify-content: space-between;
}

/* Upcoming matches section */
.upcomingMatches,
.youtubeHighlighted {
  flex: 1;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .bodyArea {
    flex-direction: column;
  }

  .youtubeHighlighted {
    width: 100%; /* Adjust width for smaller screens */
    max-width: none; /* Remove max-width constraint */
    height: auto; /* Adjust height for responsiveness */
  }

  .bannerImage {
    width: 100%; /* Adjust width for smaller screens */
    height: auto; /* Adjust height to maintain aspect ratio */
  }
}

.avatar {
  padding-right: 0px;
}

.carousel-wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.home_card .ant-card-body {
  padding: 0px; /* Example style */
}
/* Carousel */
.carousel-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  width: 100%;
  height: 150px;
  position: relative;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; /* Adjust the width dynamically with JS */
}

.carousel-slide {
  flex: 0 0 100%; /* Each slide takes up 100% of the container's width */
  padding-top: 3%;
  padding-left: 3%;
  height: 200px;
}

.carousel-info {
  line-height: 1rem;
  text-align: left;
  gap: 10%;
  justify-content: space-between;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
}

.carousel-btn.left {
  left: 10px;
}

.carousel-btn.right {
  right: 10px;
}

.carousel-btn .anticon {
  font-size: 20px;
}

.articles {
  width: 100%;
}
