.paymentPlansContainers {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.plans {
  width: 22%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.icon {
  font-size: 40px;
  color: #1890ff;
  margin-bottom: 10px;
}

.planTitle {
  font-size: 24px;
  margin-top: 10px;
}

.planParagraph {
  margin: 10px 0;
}

button {
  margin-top: 10px;
}

@media screen and (max-width: 1024px) {
  .plans {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .plans {
    width: 100%;
    margin-bottom: 20px;
  }

  .paymentPlansContainers {
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .planTitle {
    font-size: 20px;
  }

  .icon {
    font-size: 30px;
  }

  h1 {
    font-size: 28px;
  }

  .planParagraph {
    font-size: 14px;
  }
}
