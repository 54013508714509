.quinellaTable .ant-table-thead > tr > th,
.quinellaTable .ant-table-tbody > tr > td {
  padding: 0px !important; /* Override padding for table header and body cells */
  width: 5px;
}

.quinellaTableMobile .ant-table-thead > tr > th,
.quinellaTableMobile .ant-table-tbody > tr > td {
  padding: 2px !important; /* Override padding for table header and body cells */
  width: 5px;
  font-size: 0.5rem;
}

.quinellaTableMobile .ant-table-tbody > tr > td {
  border-right: 1px solid #eeeded; /* Add right border */
  border-bottom: 1px solid #eeeded; /* Add right border */
}

.grey-cell {
  background-color: #eeeded; /* Light grey background */
  color: black;
}
