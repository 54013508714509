.account {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.detail {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
}

.content_title {
  font-weight: 600;
  color: #555;
}

.content {
  font-weight: 400;
  color: #333;
}

@media (max-width: 768px) {
  .account {
    padding: 15px;
    max-width: 100%;
    box-shadow: none;
    border-radius: 0;
  }

  .detail {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0;
  }

  .content_title {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .content {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .account {
    padding: 10px;
  }

  .detail {
    padding: 6px 0;
  }

  .content_title,
  .content {
    font-size: 12px;
  }
}
