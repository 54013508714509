.staticTable {
  width: 50%;
  padding-left: 10px;
}

.row-odd {
  background-color: rgb(234, 247, 252); /* light grey for odd rows */
}

.row-even {
  background-color: #ffffff; /* white for even rows */
}
