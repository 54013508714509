.odds-cell {
  display: flex;
  flex-direction: column;
}

.odds-part {
  display: flex;
  align-items: center;
}

.index {
  color: black; /* Apply black color to the index */
}

.default {
  color: black; /* Apply default color to other parts (can adjust as needed) */
}

.qplace {
  color: lightgrey; /* Apply grey color to Qplace values */
}

.quinella-horse-info .ant-table-thead > tr > th,
.quinella-horse-info .ant-table-tbody > tr > td {
  padding: 0px !important; /* Override padding for table header and body cells */
  width: 5px;
  font-size: 0.5rem;
}
