:root {
  --glow: rgba(255, 195, 26, 0.4);
}

.bg-black {
  background-color: rgb(255, 255, 255);
  height: 20vh;
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cube-container {
  height: 10rem; /* Equivalent to h-40 (40 * 0.25rem = 10rem) */
  width: 10rem; /* Equivalent to w-40 (40 * 0.25rem = 10rem) */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 750ms;
}

.hexagon {
  z-index: -2;
  position: relative;
  width: 160px;
  height: 92.38px;
  background-color: var(--glow);
  margin: 46.19px 0;
  filter: blur(20px);
}

.hexagon::before,
.hexagon::after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
}

.hexagon::before {
  bottom: 100%;
  border-bottom: 46.19px solid var(--glow);
}

.hexagon::after {
  top: 100%;
  width: 0;
  border-top: 46.19px solid var(--glow);
}

.cube {
  position: absolute;
  height: 10rem; /* Equivalent to h-40 */
  width: 10rem; /* Equivalent to w-40 */
  transition: all 750ms;
}

.cube.back {
  background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxBackground_2x_b2espr.png");
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.cube.top {
  background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxTopFlap_2x_f9cb8g.png");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.cube.left {
  background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxLeftFlap_2x_y8u4gz.png");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.cube.right {
  background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxRightFlap_2x_abexhh.png");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.powerup {
  background-size: cover;
  border-radius: 50%;
  z-index: 100;
  z-index: -5;
  margin-left: -40%;
  margin-bottom: 10%;
  transform: translateX(-60%); /* Adjust the value to move left */
}

@keyframes hover {
  from {
    transform: translateY(-0.5rem);
  }
  to {
    transform: translateY(0.5rem);
  }
}

#cube {
  animation: hover 1.5s ease-in-out infinite alternate;
  transition: transform 300ms;
  animation-play-state: running;
}
