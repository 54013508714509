.custom-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab {
  width: 25px; /* Set a fixed width */
  height: 25px; /* Set a fixed height */
  border-radius: 50%; /* Make them circular */
  background-color: rgba(190, 237, 250, 0.555);
  display: flex; /* Center content */
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  margin: 0 2px; /* Space between tabs */
  transition: background-color 0.3s, color 0.3s; /* Transition effect */
  text-align: center; /* Center text */

  margin-bottom: 10px;
  margin-top: 10px;
}

.custom-tabs .ant-tabs-tab {
  padding: "0px";
}

.custom-tabs .ant-tabs-ink-bar {
  display: none; /* Hides the default ink bar */
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab:hover {
  font-weight: 500;
  background-color: #084c8c; /* Color on hover */
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 500;
  color: white;
  background-color: #084c8c; /* Color on hover */
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
}
