/* General Layout Styles */

.ant-menu-submenu-title,
.ant-menu-item {
  color: black !important; /* Ensure text is visible on grey */
}

.ant-menu-item:hover,
.ant-menu-submenu-title:hover {
  background-color: rgb(
    222,
    235,
    237
  ) !important; /* Slightly darker grey on hover */
}

.footer {
  padding-top: 5px;
}
.custom-menue.ant-menu-item {
  background-color: white;
}

.no-padding-important {
  padding: 0px !important;
}
/* Responsive Styles */
@media (max-width: 768px) {
  /* For small screens, adjust padding and layout */
  .layout-content {
    padding: 0 16px !important; /* Reduce padding */
  }

  .layout-container {
    padding: 16px !important;
    min-height: auto; /* Adjust height */
    margin-top: 10px !important; /* Reduce margin for smaller screens */
  }

  .inner-content {
    padding: 0px !important; /* Reduce padding inside the content */
    min-height: 400px !important; /* Adapt minimum height */
  }
}

:where(.css-dev-only-do-not-override-98ntnt).ant-menu-light,
:where(.css-dev-only-do-not-override-98ntnt).ant-menu-light > .ant-menu {
  background-color: wheat !important;
}

.switch-container {
  position: relative; /* Position the container relatively */
  padding-right: 30px;
}

.custom-switch {
  float: "right";
}
/* Further optimization for even smaller screens (e.g., iPhones) */
@media (max-width: 576px) {
  .layout-content {
    padding: 0 8px !important; /* Further reduce padding for extra small screens */
  }

  .layout-container {
    padding: 10px !important;
    margin-top: 8px !important;
  }

  .inner-content {
    padding: 0px !important;
    min-height: 350px !important;
  }
}
